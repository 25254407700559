import React from "react";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@material-ui/core";

//renders a markdown file 

function RenderMD(props: any) {
  const useStyles = makeStyles((theme) => ({
    md: {
      paddingTop: 70,
      paddingLeft: 25,
      paddingRight: 25,
      flexDirection: "column",
      justifyContent: "center",
      "& img": {
        width: "65vmin",
        border: "10px black",
        borderStyle: "double",
        margin: 'auto',
      },
    },
  }));

  const { path } = props;
  const classes = useStyles();
  const [md, setMD] = React.useState<any>(undefined);

  const getMD = async (file: string) => {
    await fetch(file)
      .then((res) => res.text())
      .then((text) => {
        setMD(text);
      });
  };

  React.useEffect(() => {
    getMD(path);
  }, [path]);

  return <ReactMarkdown className={classes.md}>{md}</ReactMarkdown>;
}

export default RenderMD;
