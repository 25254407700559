import React from 'react'
import clsx from 'clsx'
import { Switch, Route, BrowserRouter, Redirect} from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'

import RenderMD from './RenderMD/RenderMD'
import Header from './Page/Header/Header'
import Sidebar from './Page/Sidebar/Sidebar'

const App: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop

  const [isSidebarOpenMobile, setIsSidebarOpenMobile] = React.useState(false)
  const [isSidebarCollapsedDesktop, setIsSidebarCollapsedDesktop] = React.useState(false)

  function handleSidebarMobileToggle() {
    setIsSidebarOpenMobile(!isSidebarOpenMobile)
  }

  function handleSidebarToggle() {
    if (isMobile) {
      setIsSidebarOpenMobile(!isSidebarOpenMobile)
    }
    else {
      setIsSidebarCollapsedDesktop(!isSidebarCollapsedDesktop)
    }
  }

  return (
    <BrowserRouter> 
    <div className={classes.dashboardContainer}>
      <div
        className={clsx(
          classes.headerContainer,
          isDesktop && classes.headerContainerDesktop,
          isDesktop &&
            isSidebarCollapsedDesktop &&
            classes.headerContainerDesktopDrawerCollapsed,
        )}
      >
        <Header onToggle={handleSidebarToggle} />
      </div>
      <div
        className={clsx(
          classes.sidebarContainer,
          isMobile && classes.sidebarContainerMobile,
          isDesktop && classes.sidebarContainerDesktop,
          isDesktop &&
            isSidebarCollapsedDesktop &&
            classes.sidebarContainerDesktopDrawerCollapsed,
        )}
      >
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={isSidebarOpenMobile}
            onClose={handleSidebarMobileToggle}
            classes={{
              paper: clsx(classes.drawer, classes.drawerMobile),
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Sidebar
              isDesktop={isDesktop}
              isMobile={isMobile}
              isSidebarCollapsedDesktop={isSidebarCollapsedDesktop}
              isSidebarOpenMobile={isSidebarOpenMobile}
              onToggleClick = {handleSidebarMobileToggle}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: clsx(
                classes.drawer,
                classes.drawerDesktop,
                isSidebarCollapsedDesktop && classes.drawerDesktopCollapsed,
              ),
            }}
            variant="permanent"
          >
            <Sidebar
              isDesktop={isDesktop}
              isMobile={isMobile}
              isSidebarCollapsedDesktop={isSidebarCollapsedDesktop}
              isSidebarOpenMobile={isSidebarOpenMobile}
            />
          </Drawer>
        </Hidden>
      </div>
      <main className={classes.content}>

      <Switch>
              <Route path="/" exact render={ () => <RenderMD path = {'/md/Menu/menu.mdown'}></RenderMD> } />

              {/* Map sub menu */}
              <Route path="/Map-Types" render={ () => <RenderMD path = {'/md/Map/mapTypes.mdown'}></RenderMD> } />
              <Route path="/Map-Controls" render={ () => <RenderMD path = {'/md/Map/mapControls.mdown'}></RenderMD> } />
              <Route path="/Measurement-Tool" render={ () => <RenderMD path = {'/md/Map/Measurement-Tool.md'}></RenderMD> } />
              <Route path="/Feature-Monitor" render={ () => <RenderMD path = {'/md/Map/FeatureMonitor.md'}></RenderMD> } />

              {/* Projects sub menu */}
              <Route path="/Proj-Create" render={ () => <RenderMD path = {'/md/Projects/projectCreate.mdown'}></RenderMD> } />
              <Route path="/Proj-CreateEditDelete" render={ () => <RenderMD path = {'/md/Projects/Project_CreateEditDelete.md'}></RenderMD> } />
              <Route path="/Proj-Delete" render={ () => <RenderMD path = {'/md/Projects/projectDelete.mdown'}></RenderMD> } />
              <Route path="/Proj-Location" render={ () => <RenderMD path = {'/md/Projects/projectLocation.mdown'}></RenderMD> } />
              <Route path="/Proj-Upload" render={ () => <RenderMD path = {'/md/Projects/Proj-Upload.md'}></RenderMD> } />
              <Route path="/Proj-Export" render={ () => <RenderMD path = {'/md/Projects/ProjectExport.md'}></RenderMD> } />
              <Route path="/Project-Geoid" render={ () => <RenderMD path = {'/md/Projects/Project_SetGeoid.md'}></RenderMD> } />

              {/* DataDictionary sub menu */}
              <Route path="/Assign-DataD" render={ () => <RenderMD path = {'/md/DataDictionary/ddAssign.mdown'}></RenderMD> } />
              <Route path="/Add-Features" render={ () => <RenderMD path = {'/md/DataDictionary/ddFeature.md'}></RenderMD> } />
              <Route path="/Custom-Att" render={ () => <RenderMD path = {'/md/DataDictionary/ddCustomAtt.md'}></RenderMD> } />
              <Route path="/Styling" render={ () => <RenderMD path = {'/md/DataDictionary/ddStyling.mdown'}></RenderMD> } />
              <Route path="/Creating-Copies-Dictionary" render={ () => <RenderMD path = {'/md/DataDictionary/DictionaryCopy.md'}></RenderMD> } />
              <Route path="/Global-Picklists" render={ () => <RenderMD path = {'/md/DataDictionary/Global-Picklists.md'}></RenderMD> } />
              <Route path="/Symbology-Lib" render={ () => <RenderMD path = {'/md/DataDictionary/DataDictionary-Symbology.md'}></RenderMD> } />

              {/* folders menu */}
              <Route path="/Folder-Add" render={ () => <RenderMD path = {'/md/Folders/SubFolder_Help.md'}></RenderMD> } />
              <Route path="/Folder-Edit&Delete" render={ () => <RenderMD path = {'/md/Folders/Folders_Edit&Delete.md'}></RenderMD> } />
              <Route path="/PM-Features-Folder" render={ () => <RenderMD path = {'/md/Layers/PointMan Features Folder.md'}></RenderMD> } />

              {/* Layers sub menu */}
              <Route path="/Upload-Layer" render={ () => <RenderMD path = {'/md/Layers/LayerUploadVector.md'}></RenderMD> } />
              <Route path="/Group-Export" render={ () => <RenderMD path = {'/md/Layers/GroupExport.md'}></RenderMD> } />
              <Route path="/Layer-Edit" render={ () => <RenderMD path = {'/md/Layers/layerEdit.md'}></RenderMD> } />
              <Route path="/WMS-WFS" render={ () => <RenderMD path = {'/md/Layers/WFS and WMS.md'}></RenderMD> } />
              <Route path="/Layer-Delete" render={ () => <RenderMD path = {'/md/Layers/layerDelete.mdown'}></RenderMD> } />
              <Route path="/Set-Style" render={ () => <RenderMD path = {'/md/Layers/Style-PMFeatures.md'}></RenderMD> } />
              <Route path="/Layer-View" render={ () => <RenderMD path = {'/md/Layers/layerView.mdown'}></RenderMD> } />
              <Route path="/Layer-Zoom" render={ () => <RenderMD path = {'/md/Layers/Zoom to layer extent_help.md'}></RenderMD> } />
              <Route path="/Layer-Raster" render={ () => <RenderMD path = {'/md/Layers/Layer-Raster-Image.md'}></RenderMD> } />
              <Route path="/Share-Layers" render={ () => <RenderMD path = {'/md/Layers/LayerShare.md'}></RenderMD> } />
              <Route path="/Data-Retrieval" render={ () => <RenderMD path = {'/md/Layers/Layer-DataRetrieval.md'}></RenderMD> } />

              <Route path="/Style-File" render={ () => <RenderMD path = {'/md/Layers/LayerStyleUpload.md'}></RenderMD> } />
              <Route path="/Style-Imported-Layers" render={ () => <RenderMD path = {'/md/Layers/Style-ImportedData.md'}></RenderMD> } />

              {/* Features sub menu */}
              <Route path="/Feature-Edit-Delete" render={ () => <RenderMD path = {'/md/Features/Editing & Deleting Features.md'}></RenderMD> } />
              <Route path="/One-Call-Feature" render={ () => <RenderMD path = {'/md/Features/ONECALL_Help.md'}></RenderMD> } />
              <Route path="/Info" render={ () => <RenderMD path = {'/md/Features/featureInfo.md'}></RenderMD> } />
              <Route path="/StreetView" render={ () => <RenderMD path = {'/md/Features/featureStreetView.md'}></RenderMD> } />
              <Route path="/Directions" render={ () => <RenderMD path = {'/md/Features/featureDirection.md'}></RenderMD> } />
              <Route path="/Pedigree" render={ () => <RenderMD path = {'/md/Features/featurePedigree.md'}></RenderMD> } />
              <Route path="/Feat-Attachments" render={ () => <RenderMD path = {'/md/Features/Cloud_Attachments.md'}></RenderMD> } />
              <Route path="/Label-Leaders" render={ () => <RenderMD path = {'/md/Features/Labels.md'}></RenderMD> } />
              <Route path="/Coordinate-Display" render={ () => <RenderMD path = {'/md/Features/Coordinate Display.md'}></RenderMD> } />
              <Route path="/Elevation-Profile" render={ () => <RenderMD path = {'/md/Features/Elevation-Profile.md'}></RenderMD> } />
              <Route path="/Site-Cal" render={ () => <RenderMD path = {'/md/Features/Site-Cal.md'}></RenderMD> } />
              <Route path="/CO-811" render={ () => <RenderMD path = {'/md/Features/Colorado 811 Positive Response.md'}></RenderMD> } />
              <Route path="/Data-Organization" render={ () => <RenderMD path = {'/md/Features/Data Organization.md'}></RenderMD> } />
              <Route path="/Feat-Restore" render={ () => <RenderMD path = {'/md/Features/FeatureRestore.md'}></RenderMD> } />


              <Route path="/Act-Reports" render={ () => <RenderMD path = {'/md/Feedback/ActivityLogs-Reports.md'}></RenderMD> } />
              <Route path="/Feat-Activity-Log" render={ () => <RenderMD path = {'/md/Features/FeatureActivityLog.md'}></RenderMD> } />
              <Route path="/User-Metrics" render={ () => <RenderMD path = {'/md/Org/Reports_UserMetrics.md'}></RenderMD> } />

              {/* line features sub menu */}              
              <Route path="/Line-Points" render={ () => <RenderMD path = {'/md/Features/LineFunctions_LineFrPoints.md'}></RenderMD> } />
              <Route path="/Line-Vertex-Remove" render={ () => <RenderMD path = {'/md/Features/LineFunctions_RemoveVertex.md'}></RenderMD> } />
              <Route path="/Line-Split&Join" render={ () => <RenderMD path = {'/md/Features/Line-Split&Join.md'}></RenderMD> } />


              {/* Forms sub menu */}
              <Route path="/Forms-Add-Proj" render={ () => <RenderMD path = {'/md/Forms/formAddProj.md'}></RenderMD> } />
              <Route path="/Forms-Add-Feat" render={ () => <RenderMD path = {'/md/Forms/formAddFeat.md'}></RenderMD> } />
              <Route path="/Forms-View" render={ () => <RenderMD path = {'/md/Forms/formView.mdown'}></RenderMD> } />
              <Route path="/Forms-Edit" render={ () => <RenderMD path = {'/md/Forms/formEdit.mdown'}></RenderMD> } />
              <Route path="/Forms-Delete" render={ () => <RenderMD path = {'/md/Forms/formDelete.mdown'}></RenderMD> } />
              <Route path="/Forms-Attachments" render={ () => <RenderMD path = {'/md/Forms/formAttach.mdown'}></RenderMD> } />
              <Route path="/Forms-Activity" render={ () => <RenderMD path = {'/md/Forms/formActivity.mdown'}></RenderMD> } />

              {/* Organization sub menu */}
              <Route path="/Manage-Org" render={ () => <RenderMD path = {'/md/Org/orgManageO.mdown'}></RenderMD> } />
              <Route path="/User-Add" render={ () => <RenderMD path = {'/md/Org/orgAddUser.mdown'}></RenderMD> } />
              <Route path="/User-Delete" render={ () => <RenderMD path = {'/md/Org/UserDelete.md'}></RenderMD> } />
              <Route path="/User-Permissions" render={ () => <RenderMD path = {'/md/Org/Pro User Permissions.md'}></RenderMD> } />
              <Route path="/Reset-Pass" render={ () => <RenderMD path = {'/md/Org/orgResetPass.mdown'}></RenderMD> } />              
              <Route path="/Activity-Log" render={ () => <RenderMD path = {'/md/Org/orgActivity.mdown'}></RenderMD> } />
              <Route path="/User-Delete" render={ () => <RenderMD path = {'/md/Org/UserDelete.md'}></RenderMD> } />
              <Route path="/Deactivate-User" render={ () => <RenderMD path = {'/md/Org/Deactivate User_Help.md'}></RenderMD> } />


              {/* Settings sub menu */}
              <Route path="/User-Prefs" render={ () => <RenderMD path = {'/md/Settings/userPrefs.md'}></RenderMD>} />
              <Route path="/Personal-Info" render={ () => <RenderMD path = {'/md/Settings/personalInfo.md'}></RenderMD>} />
              <Route path="/Feedback" render={ () => <RenderMD path = {'/md/Feedback/UserFeedback.md'}></RenderMD> } />
              <Route path="/Proj-Notif" render={ () => <RenderMD path = {'/md/Settings/Account_ProjectNotifications.md'}></RenderMD> } />

              {/* Search sub menu */}
              <Route path="/Restrict" render={ () => <RenderMD path = {'/md/search/searchRestrict.md'}></RenderMD> } />
              <Route path="/Search_Filters" render={ () => <RenderMD path = {'/md/search/SearchFilter.md'}></RenderMD> } />
              <Route path="/Customize" render={ () => <RenderMD path = {'/md/search/Search_Customize.md'}></RenderMD> } />
              <Route path="/Operators" render={ () => <RenderMD path = {'/md/search/searchOperators.md'}></RenderMD> } />
              <Route path="/Search_Export" render={ () => <RenderMD path = {'/md/search/Search_Export.md'}></RenderMD> } />

              {/* Store sub menu */}
              <Route path="/Store" render={ () => <RenderMD path = {'/md/Store/Store Overview.md'}></RenderMD> } />
              <Route path="/Store-New-User" render={ () => <RenderMD path = {'/md/Store/Adding a New PointMan Pro User.md'}></RenderMD> } />
              <Route path="/Store-User-Types" render={ () => <RenderMD path = {'/md/Store/Pro User Types.md'}></RenderMD> } />
              <Route path="/Cloud-Store-Sub" render={ () => <RenderMD path = {'/md/Store/Cloud_UserManagement.md'}></RenderMD> } />
              <Route path="/Cloud-User-Man" render={ () => <RenderMD path = {'/md/Store/Cloud - User Management.md'}></RenderMD> } />
              <Route path="/Reassign-Seat" render={ () => <RenderMD path = {'/md/Store/Cloud_ReassignSeats.md'}></RenderMD> } />
              <Route path="/Cloud-Reset-Pass" render={ () => <RenderMD path = {'/md/Store/Cloud_ResetPassword.md'}></RenderMD> } />


              {/*page not found*/}
              <Route path="/404" render={ () => <RenderMD path = {'/md/404.mdown'}></RenderMD> } />
              <Redirect to="/404"/>
              
              </Switch>
        <div className={classes.headerSpacer} />
        
      </main>
    </div>
    </BrowserRouter>

  )
}

const menuWidth = 300
const widthCollapsed = 0

const useStyles = makeStyles(theme => ({
  root:{
    display: 'flex',
  },
  dashboardContainer: {
    display: 'flex',
  },
  headerContainer: {
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerContainerDesktop: {
    left: 'auto',
    width: `calc(100% - ${menuWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerContainerDesktopDrawerCollapsed: {
    width: `calc(100% - ${widthCollapsed}px)`,
  },
  sidebarContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: menuWidth,
      flexShrink: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarContainerMobile: {
    width: 0,
  },
  sidebarContainerDesktop: {
    width: menuWidth,
  },
  sidebarContainerDesktopDrawerCollapsed: {
    [theme.breakpoints.up('md')]: {
      width: widthCollapsed,
    },
  },
  drawer: {},
  drawerMobile: {
    width: menuWidth,
  },
  drawerDesktop: {
    background: '#0A395F',
    width: '100%',
    position: 'absolute',
  },
  drawerDesktopCollapsed: {
    overflowX: 'hidden',
  },
  headerSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    flexDirection: 'column',
    display: 'flex',
    width: '100vw',
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))


export default App