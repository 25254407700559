import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {Link} from "react-router-dom"
import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close';
import logo2 from '../images/PMbyProstar2.png'


import SidebarNav from './SidebarNav'

interface SidebarProps {
  onToggleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDesktop: boolean
  isMobile: boolean
  isSidebarCollapsedDesktop: boolean
  isSidebarOpenMobile: boolean
}

const Sidebar = (props: SidebarProps) => {
  const { isDesktop, isSidebarCollapsedDesktop, onToggleClick } = props

  const classes = useStyles(props)

  if (isDesktop){
    return (
      <aside className={classes.sidebar}>
        <div className={classes.sidebarHeaderDesktop}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
            >
            </Typography>
        </div>
        
        <Link to = {{ pathname: 'https://pointman.com/support/'}} target = "blank">
        <img src={logo2} alt="PM-Cloud" width = "270"/>
        </Link>

        <SidebarNav isCollapsed={isDesktop && isSidebarCollapsedDesktop} />
      </aside>
    )
  }

  else{
    return (
      <aside className={classes.sidebar}>
        <div className={classes.sidebarHeaderMobile}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
            >
            </Typography>
        </div>
        <IconButton
            edge="start"
            color="inherit"
            aria-label="Toggle sidebar"
            onClick={onToggleClick}
            className={clsx(classes.closeButton)}
          >
          <CloseIcon />
          </IconButton>
 
        <Link to = {{ pathname: 'https://pointman.com/support/'}} target = "blank">
        <img src={logo2} alt="PM-Cloud" width = "270"/>
        </Link>

        <SidebarNav isCollapsed={isDesktop && isSidebarCollapsedDesktop} />
      </aside>
    )
  }
}

const useStyles = makeStyles(theme => ({
  sidebar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: '#0A395F',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  sidebarHeaderDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    paddingTop: '10px',
  },
  sidebarHeaderMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  closeButton: {
    color: '#fff',
    flexDirection: 'row-reverse',
    marginLeft: '246px',
  },
  tagline: {
    fontSize: 8,
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    marginTop: 0,
    color: '#fff',
    borderRadius: 0,
    right: 0,
  },
}))

export default Sidebar
