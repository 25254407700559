import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import SidebarNavItems from './SidebarNavItems'

export interface SidebarNavProps {
  isCollapsed: boolean
}

const SidebarNav = (props: SidebarNavProps) => {
  const { isCollapsed } = props
  const classes = useStyles()

  const MenuItemList = [
    {
      name: 'Menu',
      link: '/',
      path: '',
    },
    {
      name: 'Cloud Subscription Management',
      items: [
        {
          name: 'Cloud Subscription Purchase',
          link: '/Cloud-Store-Sub',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Cloud User Management',
          link: '/Cloud-User-Man',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Reassign Seats',
          link: '/Reassign-Seat',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },{
          name: 'Reset Password',
          link: '/Cloud-Reset-Pass',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'User Types',
          link: '/Store-User-Types',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Map',
      items: [
        {
          name: 'Feature Monitor',
          link: '/Feature-Monitor',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Map Types',
          link: '/Map-Types',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Map Controls',
          link: '/Map-Controls',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Measurement Tool',
          link: '/Measurement-Tool',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Coordinate Display',
          link: '/Coordinate-Display',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Projects',
      items: [
        {
          name: 'Create/Edit/Delete Project',
          link: '/Proj-CreateEditDelete',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Export Project',
          link: '/Proj-Export',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Setting a Project Geoid',
          link: '/Project-Geoid',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Site Calibration',
          link: '/Site-Cal',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },  
        {
          name: 'Upload Project',
          link: '/Proj-Upload',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: '811 One Call Ticket Support',
          link: '/One-Call-Feature',
          Icon: KeyboardArrowRightIcon,
          path: '',
        }, 
      ]
    },
    {
      name: 'Data Dictionary',
      items: [
        {
          name: 'Assign Data Dictionary',
          link: '/Assign-DataD',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Add Features',
          link: '/Add-Features',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Creating Copies',
          link: '/Creating-Copies-Dictionary',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Custom Attributes',
          link: '/Custom-Att',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Global Picklists',
          link: '/Global-Picklists',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Styling',
          link: '/Styling',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Symbology Library',
          link: '/Symbology-Lib',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Folders',
      items: [
        {
          name: 'Add Folders/Subfolders',
          link: '/Folder-Add',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Edit/Delete Folder',
          link: '/Folder-Edit&Delete',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Export Folder',
          link: '/Group-Export',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'PointMan Features Folder',
          link: '/PM-Features-Folder',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Layers',
      items: [
        {
          name: 'Viewing Layer',
          link: '/Layer-View',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Edit Layer',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Overview',
              link: '/Layer-Edit',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Data Retrieval',
              link: '/Data-Retrieval',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },
        {
          name: 'Styling Layers',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Styling PointMan Feature Layers',
              link: '/Set-Style',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Style An Imported Layer',
              link: '/Style-Imported-Layers',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Upload Style Files',
              link: '/Style-File',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },
        {
          name: 'Import Layer',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Raster Image Layer',
              link: '/Layer-Raster',
              Icon: KeyboardArrowRightIcon,
              path: ''
            },
            {
              name: 'Upload/Import Layer Vector',
              link: '/Upload-Layer',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },
        {
          name: 'Delete Layer',
          link: '/Layer-Delete',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Export Layer',
          link: '/Group-Export',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Share Layers',
          link: '/Share-Layers',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Zoom to Layer Extent',
          link: '/Layer-Zoom',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Features',
      items: [ 
        {
          name: 'Line Functions',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Create a Line from Points',
              link: '/Line-Points',
              Icon: KeyboardArrowRightIcon,
              path: ''
            },
            {
              name: 'Remove Vertex',
              link: '/Line-Vertex-Remove',
              Icon: KeyboardArrowRightIcon,
              path: ''
            },
            {
              name: 'Split & Join',
              link: '/Line-Split&Join',
              Icon: KeyboardArrowRightIcon,
              path: ''
            },
          ],
        },
        {
          name: 'Feature Panel',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Info',
              link: '/Info',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Attachments',
              link: '/Feat-Attachments',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Feature Activity Logs',
              link: '/Feat-Activity-Log',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'StreetView',
              link: '/StreetView',
              Icon: KeyboardArrowRightIcon,
              path: '',
            }, 
            {
              name: 'Directions',
              link: '/Directions',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Pedigree',
              link: '/Pedigree',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Elevation Profile',
              link: '/Elevation-Profile',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },     
        {
          name: 'Colorado 811 Positive Response',
          link: '/CO-811',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Data Organization',
          link: '/Data-Organization',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Edit and Delete Features',
          link: '/Feature-Edit-Delete',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Labels and Leaders',
          link: '/Label-Leaders',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Restoring Features',
          link: '/Feat-Restore',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },   
      ]
    },
    {
      name: 'Forms',
      items: [
        {
          name: 'Add Form to Project',
          link: '/Forms-Add-Proj',
          Icon: KeyboardArrowRightIcon,
          path: ''
        },
        {
          name: 'Add Form to Feature',
          link: '/Forms-Add-Feat',
          Icon: KeyboardArrowRightIcon,
          path: ''
        },
        {
          name: 'Edit Form',
          link: '/Forms-Edit',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'View Form',
          link: '/Forms-View',
          Icon: KeyboardArrowRightIcon,
          path: ''
        },
        {
          name: 'Delete Form',
          link: '/Forms-Delete',
          Icon: KeyboardArrowRightIcon,
          path: ''
        },
      ]
    },
    {
      name: 'Organization Settings',
      items: [
        {
          name: 'Activity Logs',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Activity Reports',
              link: '/Act-Reports',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'User Metric Reports',
              link: '/User-Metrics',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },
        {
          name: 'Manage Users',
          Icon: KeyboardArrowRightIcon,
          path: '',
          items:[
            {
              name: 'Add User',
              link: '/User-Add',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Deactivate/Reactivate User',
              link: '/Deactivate-User',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Delete User',
              link: '/User-Delete',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'User Permissions',
              link: '/User-Permissions',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
            {
              name: 'Reset User Password',
              link: '/Reset-Pass',
              Icon: KeyboardArrowRightIcon,
              path: '',
            },
          ],
        },
        {
          name: 'Activity Logs',
          link: '/Activity-Log',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Manage Organization',
          link: '/Manage-Org',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Account Settings',
      items: [
        {
          name: 'Personal Info',
          link: '/Personal-Info',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Project Notifications',
          link: '/Proj-Notif',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'User Preferences',
          link: '/User-Prefs',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Submit User Feedback',
          link: '/Feedback',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    {
      name: 'Search Feature',
      items: [
        {
          name: 'Customize',
          link: '/Customize',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Export from Search',
          link: '/Search_Export',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
        {
          name: 'Filters',
          link: '/Search_Filters',
          Icon: KeyboardArrowRightIcon,
          path: '',
        },
      ]
    },
    
  //  {
  //    name: 'Sending Feedback',
  //    link: '/Feedback',
  //    path: '',
  //  },
  ]

  return (
    <div>
      <List className={classes.navList} disablePadding>
        <SidebarNavItems isCollapsed={isCollapsed} items={MenuItemList} />
      </List>

    </div>
  )
}

const menuWidth = 300
//const widthCollapsed = 0

const useStyles = makeStyles(theme =>
  createStyles({
    navList: {
      width: menuWidth,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      background: '#0A395F',
      paddingBottom: '30px',
    },
    navListHeader: {
      textAlign: 'center',
    },
  }),
)

export default SidebarNav