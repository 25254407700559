import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import IconMenu from '@material-ui/icons/Menu'
import logo from '../images/PMbyProstar.png'

interface HeaderProps {
  onToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Header = ({ onToggle }: HeaderProps) => {
  const classes = useStyles()

  return (
    <AppBar position="absolute" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Toggle sidebar"
          onClick={onToggle}
          className={clsx(classes.menuButton)}
        >
        <IconMenu />
        </IconButton>

        <img src={logo} alt="logo" height="50"/>

      </Toolbar>
    </AppBar>

    
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    background: '#ececec',
    color: '#0A395F',
    boxShadow: 'none',
    display: 'flex',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  menuButton: {},
}))

export default Header
